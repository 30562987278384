@tailwind base;
@tailwind components;
@tailwind utilities;

/* css File: */

html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.main-container {}

.burger-menu {
  fill: var(--color-primary);
}

@media screen and (max-width: 400px) {
  .wallets-container .single-wallet {
    font-size: 0.7rem;
  }

  .smaller-text-mobile {
    font-size: 12px;
  }
}

.max-width-content {
  max-width: fit-content;
}

.selected-status {
  bottom: -2px;
}

.main-container {
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
}

.login-page {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
}

.login-page::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0.8;
  top: 0;
  left: 0;
  z-index: 0;
}

/* calendar */
.react-calendar {
  font-family: "Open Sans" !important;
  border: 1px solid #e6e6e6 !important;
  border-radius: 4px;
  background: transparent !important;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--color-secondary) !important;
}

.react-calendar__tile--now {
  background-color: transparent !important;
  color: var(--color-secondary) !important;
  border: 1px solid var(--color-secondary) !important;
}

.react-calendar__tile--active,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus  {
  background-color: var(--color-secondary) !important;
  color: var(--color-primary) !important;
  border: 0px !important;
}

.eventsPage .activeFilter,
.eventsPage .rings {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  border: 3px solid var(--color-primary);
}

.eventsPage .my {
  border: solid 3px #afc7bd;
}

.eventsPage .filter_title {
  font-size: 14px;
  text-align: left;
}

/* .eventsPage .events_header {
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 16px;
} */

.eventsPage .events_card .eventCard {
  /* width: 23%; */
  /* margin-right: 20px; */
  /* margin-bottom: 20px; */
  padding: 16px 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.eventsPage .events_card .eventCard .type {
  width: 4px;
  height: 44px;
  margin: 0 16px 0 0;
  position: absolute;
}

.eventsPage .events_card .eventCard .auction {
  background-color: var(--color-primary);
}

.eventsPage .events_card .eventCard .raffle {
  background-color: #c3a875;
}

.eventsPage .events_card .eventCard .eventDate {
  display: flex;
  padding: 0 20px;
}

.eventsPage .events_card .eventCard .eventDate .date {
  margin-right: 10px;
  text-align: left;
}

.eventsPage .events_card .eventCard .eventDate .date h2 {
  font-size: 32px;
}

.eventsPage .events_card .eventCard .eventDate .day_month {
  display: flex;
  flex-direction: column;
}

.eventsPage .events_card .eventCard .eventDate .day_month .day {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.eventsPage .events_card .eventCard .eventDate .day_month .month {
  font-size: 12px;
  text-align: left;
  opacity: 0.9;
}

.eventsPage .events_card .eventCard .eventDetails h2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #191919;
}

.eventsPage .events_card .eventCard .eventDetails p {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px;
  color: #4d4d4d;
  opacity: 0.9;
}

.event_details_page {
  padding: 30px;
  display: flex;
  width: 100%;
}

.event_details_page .event_calender {
  width: 20%;
}

.event_details_page .event_registration {
  width: 30%;
  padding: 16px 16px 17px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  height: 100%;
}

.event_details_page .event_registration .is-invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  border: solid 1.5px #e40202;
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  box-shadow: 0 0 0.5em #e40202;
}

.event_details_page .event_registration span {
  color: #e40202;
  font-size: 0.8rem;
  display: block;
}

.event_details_page .past_speakerDetail {
  width: 25%;
}

.event_details_page .past_speakerDetail .authorCard {
  margin: 0px;
}

@media (max-width: 767px) {
  .event_details_page {
    display: block;
    padding: 0px;
    margin-top: 65px;
  }

  .event_details_page .event_calender {
    display: none;
  }

  .event_details_page .event_details {
    width: 100%;
  }

  .event_details_page .event_details .event_detail_card {
    margin: 0px;
  }

  .event_details_page .event_details .event_detail_card .event_subheader {
    margin-top: 1rem;
  }

  .event_details_page .event_details .event_detail_card .event_subheader div {
    margin-right: 0px;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #000;
    display: flex;
    justify-content: space-evenly;
    min-width: 29%;
    align-items: center;
    margin-right: 8px;
  }

  .event_details_page .event_details .event_detail_card .event_subheader div svg {
    min-width: 24px;
  }

  .event_details_page .event_details .event_detail_card .event_wte {
    font-size: 14px;
    text-align: left;
    color: #4d4d4d;
  }

  .event_details_page .event_registration {
    width: 100%;
    margin-bottom: 1rem;
  }

  .m_filter {
    display: flex;
    align-items: center;
    background: #fff;
  }

  .m_filter .m_filters h4 {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    text-align: center;
  }

  .m_filter .m_filters.all,
  .m_filter .m_filters.whitelist,
  .m_filter .m_filters.public {
    border-bottom: 2px solid #333;
  }

  .m_filter .m_filters.all h4,
  .m_filter .m_filters.raffles h4,
  .m_filter .m_filters.auctions h4 {
    font-weight: bold;
  }
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.text-secondary {
  color: var(--color-secondary);
}

.text-secondary::placeholder {
  color: var(--color-secondary);
}

.border-secondary {
  border-color: var(--color-secondary);
}

.focus\:outline-none:focus {
  --tw-ring-color: none;
}

.read-more-button {
  font-weight: bold;
}

.read-more-button:hover {
  cursor: pointer;
  color: var(--color-primary);
}

.unity-container {
  @apply flex flex-1 justify-center items-center;
}

.unity-container .container {
  @apply flex justify-center flex-col w-full items-center;
}

.unity {
  width: 800px;
  height: 600px;
}

.social-icons {
  @apply flex flex-row gap-4 mr-4;
}

.social-icon path {
  fill: var(--color-secondary) !important;
}